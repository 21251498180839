import insights from 'search-insights'
import { useAlgoliaApi } from '@/composables/api/useAlgolia'
import type { CampaignGiftGetResponseBody } from '@/types/redemption'
import { computed, ref, useCookie, onMounted, useRuntimeConfig } from '#imports'
import type { SearchResponse } from '@algolia/client-search'

export const getAlgoliaInsights = () => {
  const loggedUser: any = useCookie('user')

  const eventInsights = computed(() => insights)

  onMounted(() => {
    if (loggedUser?.id) insights('setUserToken', 'vsf_user_' + loggedUser?.id)
  })

  return {
    eventInsights,
  }
}

export const useGetAlgoliaSearchIndex = () => {
  const guestObj: any = useCookie('guest').value
  const algoliaIndex = guestObj?.gift?.algoliaIndex

  if (algoliaIndex) {
    return algoliaIndex
  }

  const config = useRuntimeConfig()
  return 'akeneo_b2b_$env_redemption_us_products'.replace(
    '$env',
    config.public.environment as string,
  )
}

export const useGetAlgoliaConfigIndex = () => {
  const searchIndex = useGetAlgoliaSearchIndex()
  return searchIndex.replace('products', 'config')
}

export const transformFilter = (filterString: string) => {
  return filterString
    .split(' OR ')
    .map((item) => item.replace(/(\w+)(:)([^)]+)/, '$1$2"$3"')) // this is for adding quotes to the values
    .join(' OR ')
}

export const useZenniAlgoliaSearch = <T>(customIndex?: string) => {
  const loading = ref(false)
  const ALGOLIA_SEARCH_INDEX = useGetAlgoliaSearchIndex()
  const { search: searchAlgolia } = useAlgoliaApi()
  const result = ref<SearchResponse<T> | null>(null)
  const search = async (searchParams: any, frameFilter?: string) => {
    loading.value = true
    // we need to figure out if the current filter is a product or a product variant
    // and add the corresponfing catalog filter
    let currentFilter = searchParams.requestOptions?.filters
    const catalogFilter =
      searchParams.catalogFilter as CampaignGiftGetResponseBody['catalogFilter']
    if (catalogFilter) {
      for (const [objectType, filter] of Object.entries(catalogFilter)) {
        if (currentFilter.includes(`objectType:"${objectType}"`) && filter) {
          currentFilter = `${currentFilter} AND ${transformFilter(
            filter as string,
          )}`
        }
      }
    }
    if (frameFilter) {
      currentFilter = `${currentFilter} AND ${frameFilter}`
    }
    const res = await searchAlgolia(customIndex || ALGOLIA_SEARCH_INDEX, {
      ...searchParams,
      requestOptions: {
        ...searchParams.requestOptions,
        filters: currentFilter,
      },
    })
    result.value = res
    loading.value = false
    return res
  }

  return {
    search,
    result,
    loading,
    searchGetters: {
      getItems: (res: any) => res?.hits || null,
    },
  }
}
