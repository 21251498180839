import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { jwtDecode } from 'jwt-decode'
import { type UserJWT, UserRole, rolePriority } from '@/types/jwt'
import type { StoreGetStoreResponse } from '@/types/redemption'
import { useUserClientStores } from '@/composables/api/useUserClientStores'
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import { getCookieDomain } from '@/utils/helper/getCookieDomain'
import { persistedState } from '#imports'
import type { FetchError } from 'ofetch'

export const useUserStore = defineStore(
  'user',
  () => {
    const siteConfigurationStore = useSiteConfigurationStore()
    const clientStoresApi = useUserClientStores()

    const userToken = ref<string>('')
    const user = ref<UserJWT | null>()
    const roles = ref<UserRole[]>()
    const currentRole = ref<UserRole | null>(null)
    const store = ref<StoreGetStoreResponse | null>(null)
    const loading = ref<boolean>(false)

    const assignRole = (): UserRole => {
      if (!roles.value || roles.value.length === 0) {
        return UserRole.Employee
      }

      if (roles.value.length === 1) {
        return roles.value[0]
      }

      for (const role of rolePriority) {
        if (roles.value.includes(role)) {
          return role
        }
      }

      return UserRole.Employee
    }

    const setUser = (token: string): void => {
      userToken.value = token
      user.value = jwtDecode<UserJWT>(userToken.value)
      roles.value = user.value?.roles ?? []
      currentRole.value = assignRole()
    }

    const setStore = async (newStore: StoreGetStoreResponse): Promise<void> => {
      if (newStore) {
        store.value = newStore
        await siteConfigurationStore.getSiteConfiguration(
          `${newStore.storeCode?.replace('_', ' ')} employer` ?? '',
        )
      }
    }

    const initials = computed<string>(() =>
      user.value?.firstName && user.value?.lastName
        ? `${user.value.firstName[0]}${user.value.lastName[0]}`
        : '',
    )

    const logout = (): void => {
      userToken.value = ''
      store.value = null
      currentRole.value = null
      user.value = null
      loading.value = false
    }

    const updateStore = async (storeId: string): Promise<FetchError | void> => {
      if (user.value && storeId) {
        const { data, error } = await clientStoresApi.switchStore(storeId)

        if (error.value) {
          return error.value
        } else if (data.value) {
          setUser(data.value.token)
          setStore({
            storeId: data.value.storeId,
            storeCode: data.value.storeCode,
            storeName: data.value.storeName,
            partnerId: data.value.partnerId,
          })
        }
      }
    }

    const isAMPortalUser = computed<boolean>(
      () => currentRole.value === UserRole.AMPortalUser,
    )
    const isAdminFinance = computed<boolean>(
      () => currentRole.value === UserRole.AdminFinance,
    )
    const isPartnerFinance = computed<boolean>(
      () => currentRole.value === UserRole.PartnerFinance,
    )
    const isEmployer = computed<boolean>(
      () => currentRole.value === UserRole.Employer,
    )
    const isEmployee = computed<boolean>(
      () => currentRole.value === UserRole.Employee,
    )

    const isAllowed = computed<boolean>(
      () =>
        isAMPortalUser.value ||
        isAdminFinance.value ||
        isPartnerFinance.value ||
        isEmployer.value,
    )

    return {
      loading,
      userToken,
      user,
      roles,
      currentRole,
      initials,
      store,
      isAMPortalUser,
      isAdminFinance,
      isPartnerFinance,
      isEmployer,
      isEmployee,
      isAllowed,
      setUser,
      setStore,
      updateStore,
      logout,
    }
  },
  {
    persist: {
      storage: persistedState.cookiesWithOptions({
        sameSite: 'strict',
        domain: getCookieDomain(),
        secure: process.env.NODE_ENV === 'production',
        maxAge: 3600, // 1 hour
      }),
      paths: ['userToken', 'user', 'roles', 'currentRole', 'initials', 'store'],
    },
  },
)
