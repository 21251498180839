import { ref, reactive, computed } from 'vue'
import { defineStore } from 'pinia'
import { jwtDecode } from 'jwt-decode'
import { FetchError } from 'ofetch'
import { useUserClientStores } from '@/composables/api/useUserClientStores'
import { useGift } from '@/composables/api/useGift'
import { useOtp } from '@/composables/api/useOtp'
import type { CampaignGiftGetResponseBody } from '@/types/redemption'
import { useIntervalFn } from '@vueuse/core'
import { persistedState } from '#imports'

const GIFT_DATA_EXPIRY = 60 * 60 * 1000 // 1 hour in milliseconds
const REFRESH_INTERVAL = 60000 // 1 minute

export const useGuestStore = defineStore(
  'guest',
  () => {
    const guest = reactive<{
      email: string | undefined
      storeId: string | undefined
      userId: string | undefined
      token: string | undefined
      loggedInToken: string | undefined
    }>({
      email: undefined,
      storeId: undefined,
      userId: undefined,
      token: undefined,
      loggedInToken: undefined,
    })

    const gift = ref<CampaignGiftGetResponseBody | null>(null)
    const dependents = ref<CampaignGiftGetResponseBody[] | null>(null)
    const currentGiftId = ref<string>('')
    const lastGiftFetchTimestamp = ref<number>(0)
    const lastDependentFetchTimestamp = ref<number>(0)

    const currentGift = computed<CampaignGiftGetResponseBody | null>(() => {
      if (gift.value && gift.value.id === currentGiftId.value) {
        return gift.value
      }

      return dependents.value?.find((g) => g.id === currentGiftId.value) || null
    })

    const loading = computed<boolean>(
      () => dependents.value === null || gift.value === null || !guest.token,
    )

    const isSafetyCatalog = computed(
      () => currentGift.value?.policy?.catalogs?.includes('safety') || false,
    )

    const setCurrentGift = (targetGiftId: string) => {
      currentGiftId.value = targetGiftId
    }

    const setLoggedInToken = (token: string) => {
      guest.loggedInToken = token
    }

    const { switchStore } = useUserClientStores()
    const switchStoreToken = async (storeId: string) => {
      if (guest.loggedInToken) {
        requestError.value = null
        const { data, error } = await switchStore(storeId)
        if (data.value?.token) {
          setLoggedInToken(data.value.token || '')
        } else if (error) {
          requestError.value = error.value
        }
      }
    }

    const calculateGiftAmt = (g: CampaignGiftGetResponseBody): number => {
      if (g.usage?.type === 'freeItem') {
        return g.usage.budget ?? 0
      } else if (g.usage?.type === 'giftCard') {
        return g.usage.balance ?? 0
      }
      return 0
    }

    const giftAmount = computed(() =>
      gift.value ? calculateGiftAmt(gift.value) : 0,
    )

    const requestError = ref<FetchError | null>()

    const { getGiftById, getDependentGifts } = useGift()
    const { guestLogin } = useOtp()

    const loadDependents = async (email?: string) => {
      const emailToUse = email || guest.email || ''
      const { data, error } = await getDependentGifts(emailToUse)

      if (data.value) {
        dependents.value = []
        data.value.forEach((g) => {
          if (g.id !== gift.value?.id) {
            dependents.value?.push(g)
          }
        })
        lastDependentFetchTimestamp.value = Date.now()
      } else if (error.value) {
        // eslint-disable-next-line no-console
        console.log(error.value)
        dependents.value = []
      }
    }

    const loadGift = async (id: string) => {
      const { data, error } = await getGiftById(id)
      if (data.value) {
        gift.value = data.value
        ;({
          email: guest.email,
          storeId: guest.storeId,
          userId: guest.userId,
        } = data.value)

        if (!currentGiftId.value) {
          currentGiftId.value = data.value.id
        }
        lastGiftFetchTimestamp.value = Date.now()
      } else if (error.value) {
        requestError.value = error.value
      }
    }

    useIntervalFn(async () => {
      const shouldRefreshGift =
        Date.now() - lastGiftFetchTimestamp.value > GIFT_DATA_EXPIRY

      if (guest.token && gift.value?.id && shouldRefreshGift) {
        await loadGift(gift.value.id)
      }
    }, REFRESH_INTERVAL)

    useIntervalFn(async () => {
      const shouldRefreshDependents =
        Date.now() - lastDependentFetchTimestamp.value > GIFT_DATA_EXPIRY

      if (guest.token && guest.email && shouldRefreshDependents) {
        await loadDependents(guest.email)
      }
    }, REFRESH_INTERVAL)

    const loginGuestUser = async () => {
      const { data, error } = await guestLogin({
        userEmail: guest.email ?? '',
        userId: guest.userId ?? '',
        storeId: guest.storeId ?? '',
      })

      if (data.value) {
        guest.token = data.value.token
      } else if (error.value) {
        requestError.value = error.value
      }
    }

    const reset = () => {
      gift.value = null
      currentGiftId.value = ''
      dependents.value = null
      guest.email = undefined
      guest.storeId = undefined
      guest.userId = undefined
      guest.token = undefined
      guest.loggedInToken = undefined
      requestError.value = null
      lastGiftFetchTimestamp.value = 0
      lastDependentFetchTimestamp.value = 0
    }

    const primaryUser = computed<{
      firstName: string
      lastName: string
      email: string
    } | null>(() => {
      if (guest.loggedInToken) {
        const decodedToken = jwtDecode<{
          firstName: string
          lastName: string
          email: string
        }>(guest.loggedInToken)
        return decodedToken
      }
      return null
    })

    return {
      loading,
      guest,
      primaryUser,
      gift,
      currentGiftId,
      currentGift,
      isSafetyCatalog,
      setCurrentGift,
      setLoggedInToken,
      switchStoreToken,
      dependents,
      giftAmount,
      requestError,
      loadGift,
      loadDependents,
      loginGuestUser,
      reset,
      calculateGiftAmt,
    }
  },
  {
    persist: [
      {
        storage: persistedState.cookiesWithOptions({
          sameSite: 'strict',
        }),
        paths: ['guest', 'gift', 'currentGiftId', 'lastFetchTimestamp'],
      },
    ],
  },
)
