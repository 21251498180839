import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import { useGuestStore } from '~/store/guest'
import { useGuestCartStore } from '~/store/guestCart'
import { useCheckoutStore } from '~/store/checkout'

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.meta.skipMiddleware) return
  // In case nuxt image proxyies the image url here, we need to bypass it
  // as it doesn't have the gift query param and will reset the guest store
  if (to.path.includes('/cdn-cgi/image/')) {
    return
  }

  if (process.client) {
    const { gift: giftQueryParam } = to.query
    const guestStore = useGuestStore()
    const guestCartStore = useGuestCartStore()
    const checkoutStore = useCheckoutStore()
    const { getSiteConfiguration } = useSiteConfigurationStore()

    if (
      !guestStore.gift?.id ||
      (giftQueryParam && guestStore.gift?.id !== String(giftQueryParam))
    ) {
      guestStore.reset()
      guestCartStore.reset()
      checkoutStore.reset()

      if (giftQueryParam) {
        await guestStore.loadGift(giftQueryParam as string)
        if (!guestStore.gift?.id) {
          // Gift expired
          if (guestStore.requestError?.statusCode === 410) {
            return navigateTo('/expired')
          }
          return navigateTo('/welcome', { external: true })
        }
        const storeCode = guestStore.gift?.code?.replaceAll('_', ' ') as string
        await Promise.all([
          getSiteConfiguration(storeCode),
          guestStore.loginGuestUser(),
        ])
        if (guestStore.requestError) {
          return navigateTo('/welcome', { external: true })
        }
        if (guestStore.dependents === null) {
          await guestStore.loadDependents()
        }
      } else {
        return navigateTo('/welcome', { external: true })
      }
    } else if (guestStore.gift?.id && !giftQueryParam) {
      return navigateTo({
        path: to.path,
        query: { ...to.query, gift: guestStore.gift?.id },
      })
    }
  }
})
