export enum LoginType {
  MAGICLINK_START = 'magiclink.start',
  MAGICLINK_LOGIN = 'magiclink.login',
  SSO_GOOGLE = 'sso.google',
  OTP_START = 'otp.start',
  OTP_SMS_START = 'otp.sms.start',
  OTP_LOGIN = 'otp.login',
  OTP_SMS_LOGIN = 'otp.sms.login',
  GUEST_LOGIN = 'guest.login',
}

export interface GuestLoginReqInput {
  userEmail: string
  userId: string
  storeId: string
  loginType?: LoginType.GUEST_LOGIN
}

export interface OtpStartReqInput {
  userEmail?: string
  userPhoneNumber?: string
  loginType?: LoginType.OTP_START | LoginType.OTP_SMS_START
  storeId?: string
}

export interface OtpLoginReqInput {
  code: string
  userEmail?: string
  userPhoneNumber?: string
  loginType?: LoginType.OTP_LOGIN | LoginType.OTP_SMS_LOGIN
  storeId?: string
}

export interface OtpLoginResBody {
  token: string
  [key: string]: any
}
export interface MagicLinkStartReqInput {
  userEmail: string
  loginType: LoginType.MAGICLINK_START
}
